export enum QueryParams {
	interval = 'interval',
	startTime = 'startTime',
	endTime = 'endTime',
	service = 'service',
	error = 'error',
	operation = 'operation',
	kind = 'kind',
	latencyMax = 'latencyMax',
	latencyMin = 'latencyMin',
	selectedTags = 'selectedTags',
	aggregationOption = 'aggregationOption',
	entity = 'entity',
	resourceAttributes = 'resourceAttribute',
	graphType = 'graphType',
	widgetId = 'widgetId',
	order = 'order',
	q = 'q',
	activeLogId = 'activeLogId',
	timeRange = 'timeRange',
	compositeQuery = 'compositeQuery',
	panelTypes = 'panelTypes',
	pageSize = 'pageSize',
	viewMode = 'viewMode',
	selectedFields = 'selectedFields',
	linesPerRow = 'linesPerRow',
	viewName = 'viewName',
	viewKey = 'viewKey',
	expandedWidgetId = 'expandedWidgetId',
	integration = 'integration',
	pagination = 'pagination',
	relativeTime = 'relativeTime',
	alertType = 'alertType',
	ruleId = 'ruleId',
	consumerGrp = 'consumerGrp',
	topic = 'topic',
	partition = 'partition',
	selectedTimelineQuery = 'selectedTimelineQuery',
}
